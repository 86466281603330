<template>
  <select
    id="selectCurrency"
    name=""
    class="selectCurrency form-select d-inline-block border-0"
  >
    <option
      value="AFN"
      title="Afghan afghani"
      data-symbol="Af"
    >
      Afghanistan - AFN ( Af)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      ÅLAND ISLANDS - EUR ( €)
    </option>

    <option
      value="ALL"
      title="Albanian lek"
      data-symbol="Lek"
    >
      Albania - ALL ( Lek)
    </option>

    <option
      value="DZD"
      title="Algerian dinar"
      data-symbol="دج"
    >
      Algeria - DZD ( دج)
    </option>

    <option
      value="USD"
      title="US Dollar"
      data-symbol="$"
    >
      American Samoa - USD ( $)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Andorra - EUR ( €)
    </option>

    <option
      value="AOA"
      title="Angolan kwanza"
      data-symbol="Kz"
    >
      Angola - AOA ( Kz)
    </option>

    <option
      value="XCD"
      title="East Caribbean dolla"
      data-symbol="$"
    >
      Anguilla - XCD ( $)
    </option>

    <option
      value="AQ"
      title="No universal currency"
      data-symbol="$"
    >
      Antarctica - AQ ( $)
    </option>

    <option
      value="XCD"
      title="East Caribbean dolla"
      data-symbol="$"
    >
      Antigua and Barbuda - XCD ( $)
    </option>

    <option
      value="ARS"
      title="Argentine peso"
      data-symbol="$"
    >
      Argentina - ARS ( $)
    </option>

    <option
      value="AMD"
      title="Armenian dram"
      data-symbol="֏"
    >
      Armenia - AMD ( ֏)
    </option>

    <option
      value="AWG"
      title="Aruban florin"
      data-symbol="ƒ"
    >
      Aruba - AWG ( ƒ)
    </option>

    <option
      value="AUD"
      title="Australian dollar"
      data-symbol="$"
    >
      Australia - AUD ( $)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Austria - EUR ( €)
    </option>

    <option
      value="AZN"
      title="Azerbaijani manat"
      data-symbol="ман"
    >
      Azerbaijan - AZN ( ман)
    </option>

    <option
      value="$"
      title="Bahamian Dollar"
      data-symbol="BSD"
    >
      Bahamas - $ ( BSD)
    </option>

    <option
      value="BHD"
      title="Bahraini dinar"
      data-symbol=".د.ب"
    >
      Bahrain - BHD ( .د.ب)
    </option>

    <option
      value="BDT"
      title="Bangladeshi taka"
      data-symbol="৳"
    >
      Bangladesh - BDT ( ৳)
    </option>

    <option
      value="BBD"
      title="Barbadian dollar"
      data-symbol="$"
    >
      Barbados - BBD ( $)
    </option>

    <option
      value="BYR"
      title="Belarusian ruble"
      data-symbol="p."
    >
      Belarus - BYR ( p.)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Belgium - EUR ( €)
    </option>

    <option
      value="BZD"
      title="Belize dollar"
      data-symbol="BZ$"
    >
      Belize - BZD ( BZ$)
    </option>

    <option
      value="XOF"
      title="West African CFA fra"
      data-symbol="FCFA"
    >
      Benin - XOF ( FCFA)
    </option>

    <option
      value="BMD"
      title="Bermudian dollar"
      data-symbol="$"
    >
      Bermuda - BMD ( $)
    </option>

    <option
      value="BTN"
      title="Bhutanese ngultrum"
      data-symbol="Nu.."
    >
      Bhutan - BTN ( Nu..)
    </option>

    <option
      value="BOB"
      title="Boliviano"
      data-symbol="$b"
    >
      Bolivia, Plurination - BOB ( $b)
    </option>

    <option
      value="BAM"
      title="Convertible Mark"
      data-symbol="KM"
    >
      Bosnia and Herzegovi - BAM ( KM)
    </option>

    <option
      value="BWP"
      title="Botswana pula"
      data-symbol="P"
    >
      Botswana - BWP ( P)
    </option>

    <option
      value="BRL"
      title="Brazilian real"
      data-symbol="R$"
    >
      Brazil - BRL ( R$)
    </option>

    <option
      value="USD"
      title="US Dollar"
      data-symbol="$"
    >
      British Indian Ocean - USD ( $)
    </option>

    <option
      value="BND"
      title="Brunei Dollar"
      data-symbol="$"
    >
      Brunei Darussalam - BND ( $)
    </option>

    <option
      value="BGN"
      title="Bulgarian lev"
      data-symbol="лв"
    >
      Bulgaria - BGN ( лв)
    </option>

    <option
      value="XOF"
      title="West African CFA fra"
      data-symbol="FCFA"
    >
      Burkina Faso - XOF ( FCFA)
    </option>

    <option
      value="BIF"
      title="Burundian franc"
      data-symbol="FBu"
    >
      Burundi - BIF ( FBu)
    </option>

    <option
      value="KHR"
      title="Cambodian riel"
      data-symbol="៛"
    >
      Cambodia - KHR ( ៛)
    </option>

    <option
      value="XAF"
      title="Central African CFA "
      data-symbol="FCFA"
    >
      Cameroon - XAF ( FCFA)
    </option>

    <option
      value="CAD"
      title="Canadian dollar"
      data-symbol="$"
    >
      Canada - CAD ( $)
    </option>

    <option
      value="CVE"
      title="Cape Verdean escudo"
      data-symbol="$"
    >
      Cape Verde - CVE ( $)
    </option>

    <option
      value="KYD"
      title="Cayman Islands dolla"
      data-symbol="$"
    >
      Cayman Islands - KYD ( $)
    </option>

    <option
      value="XAF"
      title="CFA Franc BEAC"
      data-symbol="FCFA"
    >
      Central African Repu - XAF ( FCFA)
    </option>

    <option
      value="XAF"
      title="Central African CFA "
      data-symbol="FCFA"
    >
      Chad - XAF ( FCFA)
    </option>

    <option
      value="CLP"
      title="Chilean peso"
      data-symbol="$"
    >
      Chile - CLP ( $)
    </option>

    <option
      value="CNY"
      title="Chinese yuan"
      data-symbol="¥"
    >
      China - CNY ( ¥)
    </option>

    <option
      value="AUD"
      title=""
      data-symbol="$"
    >
      Christmas Island - AUD ( $)
    </option>

    <option
      value="AUD"
      title=""
      data-symbol="$"
    >
      Cocos (Keeling) Isla - AUD ( $)
    </option>

    <option
      value="COP"
      title="Colombian peso"
      data-symbol="$"
    >
      Colombia - COP ( $)
    </option>

    <option
      value="KMF"
      title="Comorian franc"
      data-symbol="CF"
    >
      Comoros - KMF ( CF)
    </option>

    <option
      value="XAF"
      title=""
      data-symbol="FCFA"
    >
      Congo - XAF ( FCFA)
    </option>

    <option
      value="CDF"
      title=""
      data-symbol="FC"
    >
      Congo, The Democrati - CDF ( FC)
    </option>

    <option
      value="NZD"
      title="New Zealand dollar"
      data-symbol="$"
    >
      Cook Islands - NZD ( $)
    </option>

    <option
      value="CRC"
      title="Costa Rican col�n"
      data-symbol="₡"
    >
      Costa Rica - CRC ( ₡)
    </option>

    <option
      value="XOF"
      title="West African CFA fra"
      data-symbol="FCFA"
    >
      Cote d'Ivoire - XOF ( FCFA)
    </option>

    <option
      value="HRK"
      title="Croatian kuna"
      data-symbol="kn"
    >
      Croatia - HRK ( kn)
    </option>

    <option
      value="CUC"
      title="Cuban convertible pe"
      data-symbol="$"
    >
      Cuba - CUC ( $)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Cyprus - EUR ( €)
    </option>

    <option
      value="CZK"
      title="Czech koruna"
      data-symbol="Kč"
    >
      Czech Republic - CZK ( Kč)
    </option>

    <option
      value="DKK"
      title="Danish krone"
      data-symbol="kr"
    >
      Denmark - DKK ( kr)
    </option>

    <option
      value="DJF"
      title="Djiboutian franc"
      data-symbol="FCFA"
    >
      Djibouti - DJF ( FCFA)
    </option>

    <option
      value="XCD"
      title="East Caribbean dolla"
      data-symbol="$"
    >
      Dominica - XCD ( $)
    </option>

    <option
      value="DOP"
      title="Dominican peso"
      data-symbol="$"
    >
      Dominican Republic - DOP ( $)
    </option>

    <option
      value="USD"
      title="United States dollar"
      data-symbol="$"
    >
      Ecuador - USD ( $)
    </option>

    <option
      value="EGP"
      title="Egyptian pound"
      data-symbol="£"
    >
      Egypt - EGP ( £)
    </option>

    <option
      value="USD"
      title="United States dollar"
      data-symbol="$"
    >
      El Salvador - USD ( $)
    </option>

    <option
      value="XAF"
      title="Central African CFA "
      data-symbol="FCFA"
    >
      Equatorial Guinea - XAF ( FCFA)
    </option>

    <option
      value="ERN"
      title="Eritrean nakfa"
      data-symbol="نافكا"
    >
      Eritrea - ERN ( نافكا)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Estonia - EUR ( €)
    </option>

    <option
      value="ETB"
      title="Ethiopian birr"
      data-symbol="Br"
    >
      Ethiopia - ETB ( Br)
    </option>

    <option
      value="FKP"
      title=""
      data-symbol="£"
    >
      Falkland Islands (Ma - FKP ( £)
    </option>

    <option
      value="DKK"
      title="Danish krone"
      data-symbol="kr"
    >
      Faroe Islands - DKK ( kr)
    </option>

    <option
      value="FJD"
      title="Fijian dollar"
      data-symbol="$"
    >
      Fiji - FJD ( $)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Finland - EUR ( €)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      France - EUR ( €)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      French Guiana - EUR ( €)
    </option>

    <option
      value="XPF"
      title="CFP franc"
      data-symbol="FCFA"
    >
      French Polynesia - XPF ( FCFA)
    </option>

    <option
      value="XAF"
      title="Central African CFA "
      data-symbol="FCFA"
    >
      Gabon - XAF ( FCFA)
    </option>

    <option
      value="GMD"
      title="Dalasi"
      data-symbol="D"
    >
      Gambia - GMD ( D)
    </option>

    <option
      value="GEL"
      title="Georgian lari"
      data-symbol="ლ"
    >
      Georgia - GEL ( ლ)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Germany - EUR ( €)
    </option>

    <option
      value="GHS"
      title="Ghana cedi"
      data-symbol="¢"
    >
      Ghana - GHS ( ¢)
    </option>

    <option
      value="GIP"
      title="Gibraltar pound"
      data-symbol="£"
    >
      Gibraltar - GIP ( £)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Greece - EUR ( €)
    </option>

    <option
      value="DKK"
      title="Danish Krone"
      data-symbol="kr"
    >
      Greenland - DKK ( kr)
    </option>

    <option
      value="XCD"
      title="East Caribbean dolla"
      data-symbol="$"
    >
      Grenada - XCD ( $)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Guadeloupe - EUR ( €)
    </option>

    <option
      value="USD"
      title="US Dollar"
      data-symbol="$"
    >
      Guam - USD ( $)
    </option>

    <option
      value="GTQ"
      title="Guatemalan quetzal"
      data-symbol="Q"
    >
      Guatemala - GTQ ( Q)
    </option>

    <option
      value="GBP"
      title="British pound"
      data-symbol="£"
    >
      Guernsey - GBP ( £)
    </option>

    <option
      value="GNF"
      title="Guinean franc"
      data-symbol="FG"
    >
      Guinea - GNF ( FG)
    </option>

    <option
      value="XOF"
      title="West African CFA fra"
      data-symbol="FG"
    >
      Guinea-Bissau - XOF ( FG)
    </option>

    <option
      value="GYD"
      title="Guyanese dollar"
      data-symbol="$"
    >
      Guyana - GYD ( $)
    </option>

    <option
      value="HTG"
      title="Haitian gourde"
      data-symbol="G"
    >
      Haiti - HTG ( G)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Holy See (Vatican Ci - EUR ( €)
    </option>

    <option
      value="HNL"
      title="Honduran lempira"
      data-symbol="L"
    >
      Honduras - HNL ( L)
    </option>

    <option
      value="HKD"
      title="Hong Kong dollar"
      data-symbol="$"
    >
      Hong Kong - HKD ( $)
    </option>

    <option
      value="HUF"
      title="Hungarian forint"
      data-symbol="Ft"
    >
      Hungary - HUF ( Ft)
    </option>

    <option
      value="ISK"
      title="Icelandic kr�na"
      data-symbol="kr"
    >
      Iceland - ISK ( kr)
    </option>

    <option
      value="INR"
      title="Indian rupee"
      data-symbol="₹"
    >
      India - INR ( ₹)
    </option>

    <option
      value="IDR"
      title="Indonesian rupiah"
      data-symbol="Rp"
    >
      Indonesia - IDR ( Rp)
    </option>

    <option
      value="IRR"
      title="Iranian Rial"
      data-symbol="﷼"
    >
      Iran, Islamic Republ - IRR ( ﷼)
    </option>

    <option
      value="IQD"
      title="Iraqi dinar"
      data-symbol="ع.د"
    >
      Iraq - IQD ( ع.د)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Ireland - EUR ( €)
    </option>

    <option
      value="GBP"
      title="British pound"
      data-symbol="£"
    >
      Isle of Man - GBP ( £)
    </option>

    <option
      value="ILS"
      title="Israeli new shekel"
      data-symbol="₪"
    >
      Israel - ILS ( ₪)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Italy - EUR ( €)
    </option>

    <option
      value="JMD"
      title="Jamaican dollar"
      data-symbol="€"
    >
      Jamaica - JMD ( €)
    </option>

    <option
      value="JPY"
      title="Japanese yen"
      data-symbol="¥"
    >
      Japan - JPY ( ¥)
    </option>

    <option
      value="GBP"
      title="British pound"
      data-symbol="£"
    >
      Jersey - GBP ( £)
    </option>

    <option
      value="JOD"
      title="Jordanian dinar"
      data-symbol="JD"
    >
      Jordan - JOD ( JD)
    </option>

    <option
      value="KZT"
      title="Kazakhstani tenge"
      data-symbol="лв"
    >
      Kazakhstan - KZT ( лв)
    </option>

    <option
      value="KES"
      title="Kenyan shilling"
      data-symbol="KSh"
    >
      Kenya - KES ( KSh)
    </option>

    <option
      value="AUD"
      title="Australian dollar"
      data-symbol="$"
    >
      Kiribati - AUD ( $)
    </option>

    <option
      value="KPW"
      title="North Korean Won"
      data-symbol="₩"
    >
      Korea, Democratic Pe - KPW ( ₩)
    </option>

    <option
      value="KRW"
      title="Won"
      data-symbol="₩"
    >
      Korea, Republic of S - KRW ( ₩)
    </option>

    <option
      value="KWD"
      title="Kuwaiti dinar"
      data-symbol="د.ك"
    >
      Kuwait - KWD ( د.ك)
    </option>

    <option
      value="KGS"
      title="Kyrgyzstani som"
      data-symbol="лв"
    >
      Kyrgyzstan - KGS ( лв)
    </option>

    <option
      value="LAK"
      title="Lao kip"
      data-symbol="₭"
    >
      Laos - LAK ( ₭)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Latvia - EUR ( €)
    </option>

    <option
      value="LBP"
      title="Lebanese pound"
      data-symbol="£"
    >
      Lebanon - LBP ( £)
    </option>

    <option
      value="LSL"
      title="Lesotho loti"
      data-symbol="L"
    >
      Lesotho - LSL ( L)
    </option>

    <option
      value="LRD"
      title="Liberian dollar"
      data-symbol="$"
    >
      Liberia - LRD ( $)
    </option>

    <option
      value="LYD"
      title="Libyan Dinar"
      data-symbol="ل.د"
    >
      Libyan Arab Jamahiri - LYD ( ل.د)
    </option>

    <option
      value="CHF"
      title="Swiss franc"
      data-symbol="CHF"
    >
      Liechtenstein - CHF ( CHF)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Lithuania - EUR ( €)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Luxembourg - EUR ( €)
    </option>

    <option
      value="MOP"
      title="Pataca"
      data-symbol="MOP$"
    >
      Macao - MOP ( MOP$)
    </option>

    <option
      value="MKD"
      title="Denar"
      data-symbol="ден"
    >
      Macedonia - MKD ( ден)
    </option>

    <option
      value="MGA"
      title="Malagasy ariary"
      data-symbol="Ar"
    >
      Madagascar - MGA ( Ar)
    </option>

    <option
      value="MWK"
      title="Malawian kwacha"
      data-symbol="MK"
    >
      Malawi - MWK ( MK)
    </option>

    <option
      value="MYR"
      title="Malaysian ringgit"
      data-symbol="RM"
    >
      Malaysia - MYR ( RM)
    </option>

    <option
      value="MVR"
      title="Maldivian rufiyaa"
      data-symbol=".ރ"
    >
      Maldives - MVR ( .ރ)
    </option>

    <option
      value="XOF"
      title="West African CFA fra"
      data-symbol="FCFA"
    >
      Mali - XOF ( FCFA)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Malta - EUR ( €)
    </option>

    <option
      value="USD"
      title="United States dollar"
      data-symbol="$"
    >
      Marshall Islands - USD ( $)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Martinique - EUR ( €)
    </option>

    <option
      value="MRO"
      title="Mauritanian ouguiya"
      data-symbol="UM"
    >
      Mauritania - MRO ( UM)
    </option>

    <option
      value="MUR"
      title="Mauritian rupee"
      data-symbol="₨"
    >
      Mauritius - MUR ( ₨)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Mayotte - EUR ( €)
    </option>

    <option
      value="MXN"
      title="Mexican peso"
      data-symbol="$"
    >
      Mexico - MXN ( $)
    </option>

    <option
      value="USD"
      title="US Dollar"
      data-symbol="$"
    >
      Micronesia, Federate - USD ( $)
    </option>

    <option
      value="MDL"
      title="Moldovan leu"
      data-symbol="L"
    >
      Moldova - MDL ( L)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Monaco - EUR ( €)
    </option>

    <option
      value="MNT"
      title="Mongolian t�gr�g"
      data-symbol="₮"
    >
      Mongolia - MNT ( ₮)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Montenegro - EUR ( €)
    </option>

    <option
      value="XCD"
      title="East Caribbean dolla"
      data-symbol="$"
    >
      Montserrat - XCD ( $)
    </option>

    <option
      value="MAD"
      title="Moroccan dirham"
      data-symbol="د.م."
    >
      Morocco - MAD ( د.م.)
    </option>

    <option
      value="MZN"
      title="Mozambican metical"
      data-symbol="MT"
    >
      Mozambique - MZN ( MT)
    </option>

    <option
      value="MMK"
      title="Burmese kyat"
      data-symbol="K"
    >
      Myanmar - MMK ( K)
    </option>

    <option
      value="NAD"
      title="Namibian dollar"
      data-symbol="$"
    >
      Namibia - NAD ( $)
    </option>

    <option
      value="AUD"
      title="Australian dollar"
      data-symbol="$"
    >
      Nauru - AUD ( $)
    </option>

    <option
      value="NPR"
      title="Nepalese rupee"
      data-symbol="₨"
    >
      Nepal - NPR ( ₨)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Netherlands - EUR ( €)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Netherlands Antilles - EUR ( €)
    </option>

    <option
      value="XPF"
      title="CFP franc"
      data-symbol="FCFA"
    >
      New Caledonia - XPF ( FCFA)
    </option>

    <option
      value="NZD"
      title="New Zealand dollar"
      data-symbol="$"
    >
      New Zealand - NZD ( $)
    </option>

    <option
      value="NIO"
      title="Nicaraguan c�rdoba"
      data-symbol="C$"
    >
      Nicaragua - NIO ( C$)
    </option>

    <option
      value="XOF"
      title="West African CFA fra"
      data-symbol="FCFA"
    >
      Niger - XOF ( FCFA)
    </option>

    <option
      value="NGN"
      title="Nigerian naira"
      data-symbol="₦"
    >
      Nigeria - NGN ( ₦)
    </option>

    <option
      value="NZD"
      title="New Zealand dollar"
      data-symbol="$"
    >
      Niue - NZD ( $)
    </option>

    <option
      value="AUD"
      title="Australian Dollar"
      data-symbol="$"
    >
      Norfolk Island - AUD ( $)
    </option>

    <option
      value="USD"
      title="US Dollar"
      data-symbol="$"
    >
      Northern Mariana Isl - USD ( $)
    </option>

    <option
      value="NOK"
      title="Norwegian krone"
      data-symbol="kr"
    >
      Norway - NOK ( kr)
    </option>

    <option
      value="OMR"
      title="Omani rial"
      data-symbol="﷼"
    >
      Oman - OMR ( ﷼)
    </option>

    <option
      value="PKR"
      title="Pakistani rupee"
      data-symbol="₨"
    >
      Pakistan - PKR ( ₨)
    </option>

    <option
      value="USD"
      title="Palauan dollar"
      data-symbol="$"
    >
      Palau - USD ( $)
    </option>

    <option
      value="PS"
      title=""
      data-symbol="pound"
    >
      Palestinian Territor - PS ( pound)
    </option>

    <option
      value="PAB"
      title="Panamanian balboa"
      data-symbol="B/."
    >
      Panama - PAB ( B/.)
    </option>

    <option
      value="PGK"
      title="Papua New Guinean ki"
      data-symbol="K"
    >
      Papua New Guinea - PGK ( K)
    </option>

    <option
      value="PYG"
      title="Paraguayan guaran�"
      data-symbol="Gs"
    >
      Paraguay - PYG ( Gs)
    </option>

    <option
      value="PEN"
      title="Peruvian nuevo sol"
      data-symbol="S/."
    >
      Peru - PEN ( S/.)
    </option>

    <option
      value="PHP"
      title="Philippine peso"
      data-symbol="₱"
    >
      Philippines - PHP ( ₱)
    </option>

    <option
      value="NZD"
      title="New Zealand Dollar"
      data-symbol="$"
    >
      Pitcairn - NZD ( $)
    </option>

    <option
      value="PLN"
      title="Polish złoty"
      data-symbol="zł"
    >
      Poland - PLN ( zł)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Portugal - EUR ( €)
    </option>

    <option
      value="USD"
      title="US Dollar"
      data-symbol="$"
    >
      Puerto Rico - USD ( $)
    </option>

    <option
      value="QAR"
      title="Qatari riyal"
      data-symbol="﷼"
    >
      Qatar - QAR ( ﷼)
    </option>

    <option
      value="FRF"
      title="French Franc"
      data-symbol="₣"
    >
      Reunion - FRF ( ₣)
    </option>

    <option
      value="RON"
      title="Romanian leu"
      data-symbol="lei"
    >
      Romania - RON ( lei)
    </option>

    <option
      value="RUB"
      title="Russian ruble"
      data-symbol="руб"
    >
      Russia - RUB ( руб)
    </option>

    <option
      value="RWF"
      title="Rwandan franc"
      data-symbol="FCFA"
    >
      Rwanda - RWF ( FCFA)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Saint Barthelemy - EUR ( €)
    </option>

    <option
      value="SHP"
      title="Saint Helena Pound"
      data-symbol="£"
    >
      Saint Helena, Ascens - SHP ( £)
    </option>

    <option
      value="XCD"
      title="East Caribbean Dollar"
      data-symbol="$"
    >
      Saint Kitts and Nevi - XCD ( $)
    </option>

    <option
      value="XCD"
      title="East Caribbean dolla"
      data-symbol="$"
    >
      Saint Lucia - XCD ( $)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Saint Martin - EUR ( €)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Saint Pierre and Miq - EUR ( €)
    </option>

    <option
      value="XCD"
      title="East Caribbean Dollar"
      data-symbol="$"
    >
      Saint Vincent and th - XCD ( $)
    </option>

    <option
      value="WST"
      title="Samoan tālā"
      data-symbol="WS$"
    >
      Samoa - WST ( WS$)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      San Marino - EUR ( €)
    </option>

    <option
      value="STD"
      title="Dobra"
      data-symbol="Db"
    >
      Sao Tome and Princip - STD ( Db)
    </option>

    <option
      value="SAR"
      title="Saudi riyal"
      data-symbol="﷼"
    >
      Saudi Arabia - SAR ( ﷼)
    </option>

    <option
      value="XOF"
      title="West African CFA fra"
      data-symbol="FCFA"
    >
      Senegal - XOF ( FCFA)
    </option>

    <option
      value="RSD"
      title="Serbian dinar"
      data-symbol="Дин."
    >
      Serbia - RSD ( Дин.)
    </option>

    <option
      value="SCR"
      title="Seychellois rupee"
      data-symbol="₨"
    >
      Seychelles - SCR ( ₨)
    </option>

    <option
      value="SLL"
      title="Sierra Leonean leone"
      data-symbol="Le"
    >
      Sierra Leone - SLL ( Le)
    </option>

    <option
      value="BND"
      title="Brunei dollar"
      data-symbol="$"
    >
      Singapore - BND ( $)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Slovakia - EUR ( €)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Slovenia - EUR ( €)
    </option>

    <option
      value="SBD"
      title="Solomon Islands doll"
      data-symbol="$"
    >
      Solomon Islands - SBD ( $)
    </option>

    <option
      value="SOS"
      title="Somali shilling"
      data-symbol="S"
    >
      Somalia - SOS ( S)
    </option>

    <option
      value="ZAR"
      title="South African rand"
      data-symbol="R"
    >
      South Africa - ZAR ( R)
    </option>

    <option
      value="GS"
      title="No universal currency"
      data-symbol="�"
    >
      South Georgia and th - GS ( �)
    </option>

    <option
      value="EUR"
      title="Euro"
      data-symbol="€"
    >
      Spain - EUR ( €)
    </option>

    <option
      value="LKR"
      title="Sri Lankan rupee"
      data-symbol="₨"
    >
      Sri Lanka - LKR ( ₨)
    </option>

    <option
      value="SDG"
      title="Sudanese pound"
      data-symbol="£"
    >
      Sudan - SDG ( £)
    </option>

    <option
      value="SRD"
      title="Surinamese dollar"
      data-symbol="$"
    >
      Suriname - SRD ( $)
    </option>

    <option
      value="NOK"
      title="Norwegian Krone"
      data-symbol="kr"
    >
      Svalbard and Jan Mayen - NOK ( kr)
    </option>

    <option
      value="SZL"
      title="Swazi lilangeni"
      data-symbol="L"
    >
      Swaziland - SZL ( L)
    </option>

    <option
      value="SEK"
      title="Swedish krona"
      data-symbol="kr"
    >
      Sweden - SEK ( kr)
    </option>

    <option
      value="CHF"
      title="Swiss franc"
      data-symbol="FCFA"
    >
      Switzerland - CHF ( FCFA)
    </option>

    <option
      value="SYP"
      title="Syrian Pound"
      data-symbol="£"
    >
      Syrian Arab Republic - SYP ( £)
    </option>

    <option
      value="TWD"
      title="New Taiwan dollar"
      data-symbol="$"
    >
      Taiwan - TWD ( $)
    </option>

    <option
      value="TJS"
      title="Tajikistani somoni"
      data-symbol="TJS"
    >
      Tajikistan - TJS ( TJS)
    </option>

    <option
      value="TZS"
      title="Tanzanian Shilling"
      data-symbol="TSh"
    >
      Tanzania, United Rep - TZS ( TSh)
    </option>

    <option
      value="THB"
      title="Thai baht"
      data-symbol="฿"
    >
      Thailand - THB ( ฿)
    </option>

    <option
      value="USD"
      title="US Dollar"
      data-symbol="$"
    >
      Timor-Leste - USD ( $)
    </option>

    <option
      value="XOF"
      title="West African CFA fra"
      data-symbol="FCFA"
    >
      Togo - XOF ( FCFA)
    </option>

    <option
      value="NZD"
      title="New Zealand Dollar"
      data-symbol="$"
    >
      Tokelau - NZD ( $)
    </option>

    <option
      value="TOP"
      title="Tongan Paʻanga"
      data-symbol="T$"
    >
      Tonga - TOP ( T$)
    </option>

    <option
      value="TTD"
      title="Trinidad and Tobago "
      data-symbol="$"
    >
      Trinidad and Tobago - TTD ( $)
    </option>

    <option
      value="TND"
      title="Tunisian dinar"
      data-symbol="د.ت"
    >
      Tunisia - TND ( د.ت)
    </option>

    <option
      value="TRY"
      title="Turkish lira"
      data-symbol="₤"
    >
      Turkey - TRY ( ₤)
    </option>

    <option
      value="TMT"
      title="Turkmenistan manat"
      data-symbol="m"
    >
      Turkmenistan - TMT ( m)
    </option>

    <option
      value="USD"
      title="US Dollar"
      data-symbol="$"
    >
      Turks and Caicos Isl - USD ( $)
    </option>

    <option
      value="AUD"
      title="Australian dollar"
      data-symbol="$"
    >
      Tuvalu - AUD ( $)
    </option>

    <option
      value="UGX"
      title="Ugandan shilling"
      data-symbol="USh"
    >
      Uganda - UGX ( USh)
    </option>

    <option
      value="UAH"
      title="Ukrainian hryvnia"
      data-symbol="₴"
    >
      Ukraine - UAH ( ₴)
    </option>

    <option
      value="AED"
      title="United Arab Emirates"
      data-symbol="د.إ"
    >
      United Arab Emirates - AED ( د.إ)
    </option>

    <option
      value="GBP"
      title="British pound"
      data-symbol="£"
      selected
    >
      United Kingdom - GBP ( £)
    </option>

    <option
      value="USD"
      title="United States dollar"
      data-symbol="$"
    >
      United States - USD ( $)
    </option>

    <option
      value="UYU"
      title="Uruguayan peso"
      data-symbol="$"
    >
      Uruguay - UYU ( $)
    </option>

    <option
      value="UZS"
      title="Uzbekistani som"
      data-symbol="лв"
    >
      Uzbekistan - UZS ( лв)
    </option>

    <option
      value="VUV"
      title="Vanuatu vatu"
      data-symbol="VT"
    >
      Vanuatu - VUV ( VT)
    </option>

    <option
      value="VEF"
      title="Bolivar"
      data-symbol="Bs"
    >
      Venezuela, Bolivaria - VEF ( Bs)
    </option>

    <option
      value="VND"
      title="Vietnamese đồng"
      data-symbol="₫"
    >
      Vietnam - VND ( ₫)
    </option>

    <option
      value="USD"
      title="US Dollar"
      data-symbol="$"
    >
      Virgin Islands, Brit - USD ( $)
    </option>

    <option
      value="USD"
      title="US Dollar"
      data-symbol="$"
    >
      Virgin Islands, U.S. - USD ( $)
    </option>

    <option
      value="XPF"
      title="CFP franc"
      data-symbol="FCFA"
    >
      Wallis and Futuna - XPF ( FCFA)
    </option>

    <option
      value="YER"
      title="Yemeni rial"
      data-symbol="﷼"
    >
      Yemen - YER ( ﷼)
    </option>

    <option
      value="ZMW"
      title="Zambian kwacha"
      data-symbol="ZK"
    >
      Zambia - ZMW ( ZK)
    </option>

    <option
      value="BWP"
      title="Botswana pula"
      data-symbol="P"
    >
      Zimbabwe - BWP ( P)
    </option>
  </select>
</template>

<script>
import jQuery from 'jquery';
import Cookies from 'js-cookie';
// import select2 from '../../assets/js/select2/select2.min.js';
// window.select2 = select2;
window.select2 = require('select2');
// window.select2 = require('../../assets/js/select2/select2.min.js');
const $ = jQuery.noConflict();
function onSelectCurrencyChange(state, self) {
  if (!state.id) {
    return state.text;
  }

  var symbol = $('#selectCurrency').find(':selected').data('symbol');
  var value = $('#selectCurrency').val();
  let obj = {
    currencyCode: value,
    currencySymbol: symbol,
  };
  self.$store.commit('changeCurrency', obj);
  // currencyCode: Cookies.get('currencyCode'),
  //     currencySymbol: Cookies.get('currencySymbol'),
  return value + '-' + symbol;
}

export default {
  name: 'SelectCurrency',
  mounted() {
    let self = this;

    let currencyCode = Cookies.get('currencyCode');
    if (currencyCode != undefined) {
      $('#selectCurrency').val(currencyCode);
    }
    $('#selectCurrency').select2({
      placeholder: 'Country name or currency',
      // templateSelection: onSelectCurrencyChange,
      templateSelection: (state) => onSelectCurrencyChange(state, self),
      // dropdownCssClass: 'test',
      selectionCssClass: 'border-0 bg-primary text-white myselect',
      // selectionCssClass: 'form-select form-select ',
      // matcher: matchCustom,
      dropdownAutoWidth: true,
      // theme: 'bootstrap',
    });
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/css/select2/select2.min.css';
@import '../../assets/css/select2-bootstrap4.min.css';
.select2-container .select2-dropdown {
  /*height: 460px;*/
  /*overflow-y: scroll;*/
}
select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable {
  &:hover {
    background-color: rgb(41, 115, 115) !important;
  }
}
.myselect {
  .select2-selection__rendered {
    color: #fff !important;
    margin-right: 5px;
    font-family: 'Montserrat', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
  }

  .select2-selection__arrow b {
    border-color: #fff transparent transparent transparent !important;
  }
}
@media screen and (max-width: 991px) {
  .select2 {
    inline-size: auto !important;
    padding-left: 0 !important;
    .myselect {
      .select2-selection__rendered {
        padding-left: 0px;
        display: inline-flex;
      }
      .select2-selection__arrow {
        position: relative;

        b {
          position: relative;
          left: 0%;
        }
      }
    }
  }
}
@media screen and (min-width: 992px) {
  .select2 {
    inline-size: auto !important;
  }

  .myselect {
    .select2-selection__rendered {
      font-weight: 700;
    }
  }
}
</style>
