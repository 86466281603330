<template>
  <footer
    id="main-footer"
    class="footer-container bg-light mt-auto"
  >
    <div class="container-fluid px-lg-5">
      <div
        v-if="showImage"
        class="row g-0 mb-3"
      >
        <div class="col">
          <img
            src="/img/home/webp/card-visa.webp"
            class="img-fluid"
            alt="card visa"
          >
        </div>
        <div class="col">
          <img
            src="/img/home/webp/card-master.webp"
            class="img-fluid"
            alt="card master"
          >
        </div>
        <div class="col text-center">
          <img
            src="/img/home/webp/card-amex.webp"
            class="img-fluid"
            alt="card amex"
          >
        </div>
        <div class="col text-end">
          <img
            src="/img/home/paypal-light.jpg"
            class="img-fluid"
            alt="card paypal"
          >
        </div>
        <div class="col text-end">
          <img
            src="/img/home/webp/card-gpay.webp"
            class="img-fluid"
            alt="card gpay"
          >
        </div>
      </div>
    </div>
    <div class="top-block">
      <div class="container-fluid px-lg-5">
        <div class="row d-lg-flex justify-content-between">
          <div class="col-lg-3 col-md-6 col-xs-12 d-lg-inline-flex">
            <div class="d-lg-inline-block">
              <h2>Ready to get started?</h2>
              <div class="lead">
                We help connect people across the globe and avail travel services securely,
                affordably and rewardingly.
              </div>
              <br>
              <div class="row g-1">
                <div class="col-6">
                  <a
                    class="w-100 h-100"
                    href="https://play.google.com/store/apps/details?id=com.trepr"
                    target="_blank"
                    rel="noopener"
                  >
                    <img
                      src="/img/home/google-play-badge.png"
                      class="img-fluid w-100 h-100"
                      alt="google play"
                    >
                  </a>
                </div>
                <div class="col-6">
                  <a
                    class="w-100 h-100"
                    href="https://apps.apple.com/us/app/trepr/id1504489267?itsct=apps_box_link&itscg=30200"
                    target="_blank"
                    rel="noopener"
                  >
                    <img
                      src="/img/home/app-store-badge.svg"
                      class="img-fluid w-100 h-100"
                      alt="app store"
                    >
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-auto col-md-6 col-xs-12 text-lg-center d-lg-inline-flex">
            <div class="d-lg-inline-block">
              <h2>Quick Link</h2>
              <ul class="footer-links text-justify">
                <li>
                  <router-link :to="{ name: 'AboutUs' }">
                    About Us
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'UserTerms' }">
                    User terms
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Privacy' }">
                    Privacy
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Copyright' }">
                    Copyright
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'SecurityPolicy' }">
                    Security policy
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'NewsRoom' }">
                    News Room
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-auto col-md-6 col-xs-12 text-lg-center d-lg-inline-flex">
            <div class="d-lg-inline-block">
              <h2 class="text-lg-start">
                Tools
              </h2>
              <ul class="footer-links text-lg-start">
                <li>
                  <router-link :to="{ name: 'Prohibit' }">
                    Prohibited or Restricted Items
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{ name: 'ServiceRateCalculations', params: { serviceType: 'people' } }"
                  >
                    Service Rate Calculations
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{ name: 'CustomsExciseDuty' }"
                  >
                    Customs & Excise Duty
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'OverseasOnlineMerchants' }">
                    Overseas Online Merchants
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'WeightDimensions' }">
                    Weight &amp; Dimensions
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'ContactUs' }">
                    Contact Us
                  </router-link>
                </li>
                <!--                <li><a href="/support">Help </a></li>-->
              </ul>
            </div>
          </div>
          <div class="col-lg-auto col-md-6 col-xs-12 d-lg-inline-flex">
            <div class="d-lg-inline-block">
              <h2>Currency</h2>
              <SelectCurrency />
              <h2>Subscribe</h2>
              <div class="input-group">
                <input
                  id="textsubscription"
                  class="form-control"
                  type="text"
                  placeholder="Email address"
                  aria-label="Email address"
                  aria-describedby="btnsubscription"
                >
                <button
                  id="btnsubscription"
                  class="btn"
                >
                  <i class="fas fa-arrow-circle-right" />
                </button>
              </div>
              <br>
              <h2>Follow us on</h2>
              <div class="row">
                <div class="col">
                  <a
                    href="https://www.facebook.com/treprltd"
                    target="_blank"
                    rel="noopener"
                  ><i class="fab fa-facebook fs-3" /></a>
                </div>
                <div class="col">
                  <a
                    href="https://www.instagram.com/treprltd"
                    target="_blank"
                    rel="noopener"
                  ><i class="fab fa-instagram fs-3" /></a>
                </div>
                <div class="col">
                  <a
                    href="https://twitter.com/treprltd"
                    target="_blank"
                    rel="noopener"
                  ><i class="fab fa-twitter fs-3" /></a>
                </div>
                <div class="col">
                  <a
                    href="https://www.linkedin.com/company/trepr"
                    target="_blank"
                    rel="noopener"
                  ><i class="fab fa-linkedin fs-3" /></a>
                </div>
                <div class="col">
                  <a
                    href="https://www.pinterest.com/treprltd/"
                    target="_blank"
                    rel="noopener"
                  ><i class="fab fa-pinterest fs-3" /></a>
                </div>
                <div class="col">
                  <a
                    href="https://www.youtube.com/channel/UC46eYRybAJT1uzs3jJK_SNQ"
                    target="_blank"
                    rel="noopener"
                  ><i class="fab fa-youtube fs-3" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="m-0">
    <div class="bottom-block">
      <div class="container-fluid px-lg-5 row g-3">
        <div class="col-lg-3 col-md-6 col-xs-12">
          <strong
            class="text-white align-self-center align-self-lg-start"
          >Copyright © 2021 <a
            href="/"
            class="text-white"
          >trepr.com.</a> All Rights
            Reserved.</strong>
        </div>
      </div>
    </div>
  </footer>
  <div class="footer-icon footer-google-play">
    <a
      href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.trepr"
      target="_blank"
      rel="noopener"
    >
      <img
        src="/img/home/play_store_60_2.png"
        class="img-fluid"
        alt="google play"
      >
    </a>
  </div>
  <div class="footer-icon footer-apple-store">
    <a
      href="https://apps.apple.com/us/app/trepr/id1504489267?itsct=apps_box_link&itscg=30200"
      target="_blank"
      rel="noopener"
    >
      <img
        src="/img/home/app_store_60.png"
        class="img-fluid"
        alt="app store"
      >
    </a>
  </div>
  <div class="footer-icon footer-whatapp">
    <a
      href="https://wa.me/16464705290"
      target="_blank"
      rel="noopener"
    >
      <img
        src="/img/home/whapp.png"
        class="img-fluid"
        alt="Whatapp call me"
      >
    </a>
  </div>
</template>

<script>
import SelectCurrency from './SelectCurrency.vue';
export default {
  name: 'Footer',
  components: { SelectCurrency },
  props: {
    showImage: { default: false, type: Boolean },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/new/before/footer.scss';
</style>
