class CommonHandler {
  static formatFormData(data1) {
    let formData = Object.keys(data1)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data1[key]))
      .join('&');
    return formData;
  }

  static handleScroll() {
    var element = document.getElementById('topbar');
    if (element == null) return;
    if (document.body.scrollTop > 840 || document.documentElement.scrollTop > 840) {
      element.classList.add('bg-light');
    } else {
      element.classList.remove('bg-light');
    }
  }
  static handleScrollNormalPage() {
    var element = document.getElementById('topbar');
    let path = window.location.pathname;
    let height = 840;
    if (path != '/') {
      height = 80;
    }
    if (element == null) return;
    if (document.body.scrollTop > height || document.documentElement.scrollTop > height) {
      element.classList.add('bg-light');
    } else {
      element.classList.remove('bg-light');
    }
  }

  //This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
  static calcDistanct(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = ((lat2 - lat1) * Math.PI) / 180;
    var dLon = ((lon2 - lon1) * Math.PI) / 180;
    lat1 = (lat1 * Math.PI) / 180;
    lat2 = (lat2 * Math.PI) / 180;
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }
  static nl2br(str, is_xhtml) {
    var breakTag = is_xhtml || typeof is_xhtml === 'undefined' ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
  }
  // Converts numeric degrees to radians
  //eslint-disable-next-line
  // function toRad(value)  {
  //   let tmp = value * Math.PI / 180;
  //   return tmp;
  // }
}

module.exports = CommonHandler;
